<template>
  <div class="news" v-loading="pageLoading">
    <div class="news-cont">
        <div class="news-box cursor-pointer" v-for="(item, index) in newsArr" :key="index" @click="doOpenPage(item)">
            <img :src="item.pic" alt="">
            <div class="txt-center">{{item.txt}}</div>
        </div>
    </div>
  </div>
</template>

<script>
import { requestPost, requestGet } from "@/api/home/index";
import newsimg1 from '@/assets/images/news1.png'
import newsimg2 from '@/assets/images/news2.png'
import newsimg3 from '@/assets/images/news3.png'
import newsimg4 from '@/assets/images/news4.png'
import newsimg5 from '@/assets/images/news5.png'
export default {
  name: "Home",
  props: {},
  data() {
    return {
      pageLoading: false,
      newsArr: [{
          pic: newsimg1,
          txt: '信用评价',
          router: '/newsListsOthers/17'
      },{
          pic: newsimg2,
          txt: '信用修复',
          router: '/newsListsOthers/18'
      },{
          pic: newsimg3,
          txt: '资料下载',
          router: '/downloadFiles'
      },{
          pic: newsimg4,
          txt: '失信名单',
          router: '/newsListsOthers/20'
      },{
          pic: newsimg5,
          txt: '守信名单',
          router: '/newsListsOthers/19'
      }]
    };
  },
  mounted() {},
  methods: {
      // 打开页面
    doOpenPage(item){
      if(item.router == '/downloadFiles'){
        this.$router.push({ path: item.router})
      }else{
        this.$router.push({ path: item.router+'/'+item.txt })
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.news{
    width: 1200px;
    margin: 0 auto;
    background: #fff;
    .news-cont{
        padding: 40px;
        width: calc(100% - 80px);
        .news-box{
            width: 350px;
            display: inline-block;
            border: 1px solid #e8e8e8;
            border-radius: 3px;
            margin-top: 30px;
            margin-right: 30px;
            font-size: 0;
            img{
                width: calc(100% - 20px);
                margin: 10px;
            }
            div{
                font-size: 16px;
                margin-bottom: 10px;
            }
            
        }
        .news-box:nth-child(3){
            margin-right: 0!important;
        }
        .news-box:hover{
                opacity: 0.8;
            }
    }
}
</style>
